<!--
@author: binchen
@desc：1
@date: 2023-07-24 21:34:50
-->
<template>
  <div class="fans">
    <Fans :record="$route.query" :businessType="3"  />
  </div>
</template>

<script>
import Fans from "@/views/user/fans/index.vue";
export default {
  name: "fans",
  components: { Fans },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less"></style>
